import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { TextBlock, TextBlockDescription } from '../components/Text';
import { FacebookIcon, InstagramIcon } from '../components/Icons';
import mediaSizes from '../components/MediaSizes';

const PanelLink = styled(Link)`
    background-color: transparent;
    color: white;
    box-shadow: inset 0 0 0 2px white;
    &:hover {
        color: white;
    }
`;

const HeroImage = styled.div`
    background-position: top left;
    background-attachment: fixed;
    background-image: url(
        ${props =>
        !!props.image.childImageSharp
            ? props.image.childImageSharp.fluid.src
            : props.image});

    @media (max-width: ${mediaSizes.tablet}px) {
        background-size: cover;
        background-attachment: unset;
        background-position: center;
    }
`;

export const HeroContainer = ({ title, image, subheading }) => (
    <HeroImage image={image} className="full-width-image margin-top-0">
        <div
            style={{
                display: 'flex',
                height: '150px',
                lineHeight: '1',
                justifyContent: 'space-around',
                alignItems: 'left',
                flexDirection: 'column'
            }}
        >
            <h1
                className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                    boxShadow:
                        'rgb(228, 37, 17) 0.5rem 0px 0px, rgb(228, 37, 17) -0.5rem 0px 0px',
                    backgroundColor: 'rgb(228, 37, 17)',
                    color: 'white',
                    lineHeight: '1',
                    padding: '0.25em'
                }}
            >
                {title}
            </h1>
            {subheading && (
                <h3
                    className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                        fontFamily: 'Indie Flower',
                        boxShadow:
                            'rgb(228, 37, 17) 0.5rem 0px 0px, rgb(228, 37, 17) -0.5rem 0px 0px',
                        backgroundColor: 'rgb(228, 37, 17)',
                        width: '50%',
                        textAlign: 'center',
                        alignSelf: 'center',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em'
                    }}
                >
                    {subheading}
                </h3>
            )}
        </div>
    </HeroImage>
);

const PanelLinks = ({ link }) => (
    <React.Fragment>
        {link === 'facebook and instagram' && (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    paddingTop: '2em'
                }}
            >
                <a href="https://www.facebook.com/followtuscany/">
                    <FacebookIcon fill="white" size={30} />
                </a>
                <a href="https://www.instagram.com/followtuscany_">
                    <InstagramIcon fill="white" size={30} />
                </a>
            </div>
        )}
        {link === 'courses' && (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    paddingTop: '2em'
                }}
            >
                <PanelLink className="btn" to="/courses">
                    Courses
                </PanelLink>
            </div>
        )}
    </React.Fragment>
);

export const Panel = ({
    textFirst,
    title,
    description,
    about,
    image,
    color,
    link
}) => {
    return (
        <div className="columns">
            {textFirst ? (
                <React.Fragment>
                    <TextBlock color={color} className="column is-4">
                        {title && <h2 className="is-size-1">{title}</h2>}
                        {description && (
                            <TextBlockDescription>
                                {description}
                            </TextBlockDescription>
                        )}
                        {about && <p>{about}</p>}
                        <PanelLinks link={link} />
                    </TextBlock>
                    <Img
                        className="column is-8"
                        fluid={
                            image.childImageSharp
                                ? image.childImageSharp.fluid
                                : { src: image }
                        }
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Img
                        className="column is-8"
                        fluid={
                            image.childImageSharp
                                ? image.childImageSharp.fluid
                                : { src: image }
                        }
                    />
                    <TextBlock color={color} className="column is-4">
                        {title && <h2 className="is-size-1">{title}</h2>}
                        {description && (
                            <TextBlockDescription>
                                {description}
                            </TextBlockDescription>
                        )}
                        {about && <p>{about}</p>}
                        <PanelLinks link={link} />
                    </TextBlock>
                </React.Fragment>
            )}
        </div>
    );
};
export default HeroContainer;
