import styled from 'styled-components';

export const TextBlock = styled.div`
    background-color: ${props => props.color};
    color: white;
    padding: 40px;
`;

export const TextBlockHeading = styled.h2``;

export const TextBlockDescription = styled.p`
    font-weight: 600;
    margin-bottom: 1em;
`;

export const TextBlockAbout = styled.p``;
